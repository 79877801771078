import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));

const App = () => (
  <Router basename={"/"}>
    <Suspense fallback={<Spinner animation="border" variant="primary" />}>
      <Routes>
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  </Router>
);

export default App;
